
// @ts-ignore
import PasswordRules from "@/components/Content/Password/Rules/index.vue";
import {
	isRequired,
	isEmail,
	isPasswordPassed,
	isLoginPassed,
	// @ts-ignore
} from "@/services/rule-services";
import { TwoFactorData } from "@/interfaces/account";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
	name: "Login",
	props: {},

	data: () => ({
		localLoading: false,
		disabledSubmit: true,
		email: "",
		password: "",
		rememberMe: true,
		showPass: false,
		valid: false,
		rules: {
			isRequired,
			isEmail,
		},
		rulesField:{
            email: [],
            password: [],
        }
	}),

	created() {
		this.$nextTick(async () => {
			document.title = this.$t("login.title");
			this.disabledSubmit = !isLoginPassed(this.email, this.password);
		});
	},

	mounted() {},

	computed: {
		...mapGetters("proccess", ["isLoading"]),
	},

	components: { PasswordRules },

	methods: {
		...mapMutations("account", ["SET_TWO_FACTOR_DATA"]),
		...mapActions("auth", ["logIn"]),

		async setLoading(load: Boolean = false) {
			this.localLoading = load;
		},

		async validate() {
			let form = this.$refs.form;
			return form.validate();
		},

		async addRules(){
            this.rulesField.email = [this.rules.isRequired, this.rules.isEmail];
            this.rulesField.password = [this.rules.isRequired];
        },

        async clearRules(){
            this.rulesField.email = [];
            this.rulesField.password = [];
        },  

		async handleSubmit() {
			try {
				await this.addRules();
				if (!(await this.validate())) return;

				this.setLoading(true);

				const res = await this.logIn({
					email: this.email,
					password: this.password,
				});
				
				if(res.hasOwnProperty("two_factor")){
					const typeFactor = res.two_factor_code_from;
					const emailFactor = res.email;

					const twoFactorData = {
						typeFactor: typeFactor,
						emailFactor: emailFactor,
						email: this.email,
						password: this.password,
					} as TwoFactorData;

					this.SET_TWO_FACTOR_DATA(twoFactorData);

					this.$router.push({
						name: "TwoFactorAuthentication"
					});

				}else{
					if (res.success) {
						this.$router.push({ name: "Root" });
					}
				}
				
				this.setLoading(false);
				await this.clearRules();
			} catch (error) {
				console.error("Login::handleSubmit", { error: error });
				await this.clearRules();
				this.setLoading(false);
			}
		},

		resetForm() {
			this.valid = false;
			this.disabledSubmit = true;
			this.email = "";
			this.password = "";
			this.message = "";
		},

		handleLogOff() {
			localStorage.clear();
			this.resetForm();
			const form = this.$refs.form;
			form.validate();
			form.resetValidation();
			form.reset();
		},

		async fetchProfile() {
			return this.$store.dispatch("profile/fetchProfile");
		},
	},

	watch: {
		email(val: string) {
			this.disabledSubmit = !isLoginPassed(val, this.password);
			if(val){
				this.rulesField.email = [this.rules.isRequired, this.rules.isEmail];
			}else{
				this.rulesField.email = [];
			}
		},
		password(val: string) {
			this.disabledSubmit = !isLoginPassed(this.email, val);
			if(val){
				this.rulesField.password = [this.rules.isRequired];
			}else{
				this.rulesField.password = [];
			}
		},
	},
};
